/* eslint-disable no-dupe-keys */
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ApiRoutes, baseApiUrl } from '../constants';
import { Message } from '../lib/aiChat';
import { axiosBaseQuery } from '../lib/axios';
import { getCookies, setCookies } from '../lib/cookies';
import {
  AiTool,
  ChatHistory,
  ExtendedUserProfile,
  Program,
  Prompt,
  Session,
  StepAnswer,
  UserActivityAnswer,
  UserProfile,
  UserProgramAnswer,
  UserProgramAnswerActivity,
  UserSessionAnswer,
  UserSettings,
} from '../types';

const populate = [
  'stepAnswers',
  'stepAnswers.step',
  'stepAnswers.stepSummaryAnswer',
  'stepAnswers.activityAnswers.activity',
  'program',
  'program.needs.content',
  'program.needs.skills',
  'program.needs.skills.content',
  'program.needs.skills.need',
  'program.steps.content',
  'program.steps.activities',
  'program.steps.activities.step',
  'program.steps.activities.article',
  'program.steps.activities.session',
  'program.steps.activities.article.content',
  'program.steps.activities.article.content.picture',
  'program.steps.activities.article.content.tips',
  'program.steps.activities.article.content.more',
  'program.steps.activities.session.content.picture',
  'program.steps.activities.session.content',
  'program.steps.activities.document.title',
  'program.steps.activities.document.file',
  'program.steps.activities.document.thumbnail',
  'program.steps.activities.video.title',
  'program.steps.activities.video.url',
  'program.steps.activities.video.thumbnail',
  'program.coaches.content',
  'program.coaches.picture',
  'program.coaches.video',
  'program.content',
  'program.content.picture',
];

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({ baseUrl: baseApiUrl }),
  tagTypes: ['ChatHistory', 'StepAnswer', 'UserActivityAnswer', 'UserProfile', 'UserProgramAnswer', 'UserSettings', 'SessionFeedback', 'UserGoalActions','UserGoal','UserAction'],
  endpoints: (builder) => ({
    login: builder.mutation<{ data: { jwt: string; user: UserProfile } }, { identifier: string; password: string }>({
      query: ({ identifier, password }) => ({
        url: `${ApiRoutes.AUTH}/local`,
        method: 'POST',
        data: { identifier, password },
        params: {
          populate: ['role', 'picture', 'userSettings'],
        },
      }),
    }),
    register: builder.mutation<
      { data: { jwt: string; user: UserProfile } },
      {
        email: string;
        username: string;
        name: string;
        password: string;
        language: string;
        groups: { name: string };
        company: string;
      }
    >({
      query: ({ email, username, name, password, language, groups, company }) => ({
        url: `${ApiRoutes.AUTH}/local/register`,
        method: 'POST',
        data: {
          email,
          username,
          name,
          password,
          language,
          groups,
          company
        },
        params: {
          populate: ['role', 'picture', 'userSettings'],
        },
      }),
    }),
    createSessionFollowUp: builder.mutation<
      any,
      { scheduled_follow_up: string; previous_session: string; next_session: string; userId: string }
    >({
      query: ({ scheduled_follow_up, previous_session, next_session, userId }) => ({
        url: ApiRoutes.SESSION_FOLLOW_UP,
        method: 'POST',
        data: {
          data: {
            previous_session,
            next_session,
            scheduled_follow_up,
            user: userId,
          },
        },
      }),
    }),
    getSessionFollowUpByCurrentSessionId: builder.query<any, void>({
      query: (id) => ({
        url: `${ApiRoutes.SESSION_FOLLOW_UP}`,
        method: 'GET',
        params: {
          filters: {
            previous_session: {
              id: {
                $eq: id,
              },
            },
          },
        },
      }),
    }),
    getSessionFollowUpsByUserId: builder.query<any, void>({
      query: () => ({
        url: `${ApiRoutes.SESSION_FOLLOW_UP}/current-user`,
        method: 'GET',
      }),
    }),
    getSessionFollowUpByAllParams: builder.query<any, { scheduled_follow_up: string, previous_session: string; next_session: string; userId: string; }>({
      query:({scheduled_follow_up, previous_session, next_session, userId}) => ({
        url:`${ApiRoutes.SESSION_FOLLOW_UP}`,
        method:'GET',
        params:{
          'filters[$and]': [
            { scheduled_follow_up: scheduled_follow_up },
            { previous_session: previous_session },
            { next_session: next_session },
            { user: userId }
          ]
        }
      })
    }),
    createChathistoryFollowUpSession: builder.mutation<any, {chatHistoryId: string, userId: number,sessionFollowUpId: string}>({
      query:({ chatHistoryId, userId, sessionFollowUpId }) => ({
        url: `${ApiRoutes.SESSION_CHAT_HISTORY_FOLLOW_UP}`,
        method: 'POST',
        data: {
          data: {
            chat_history: chatHistoryId,
            user: userId,
            session_follow_up: sessionFollowUpId
          },
        },
      })
    }),
    getChatHistoryFollowUpSession: builder.query<any, {chatHistoryId?:string, userId?:string, sessionFollowUpId?:string}>({
      query: ({chatHistoryId, userId, sessionFollowUpId}) => {
        const params: { [key: string]: any } = {};
        if (chatHistoryId !== undefined) {
          params.chat_history = { id: chatHistoryId };
        }
        if (userId !== undefined) {
          params.user = userId;
        }
        if (sessionFollowUpId !== undefined) {
          params.session_follow_up = sessionFollowUpId;
        }

        return { url:`${ApiRoutes.SESSION_CHAT_HISTORY_FOLLOW_UP}`,
        method:'GET',
        params: {
          'filters[$and]':[params],
          populate: {
            chat_history: {fields: ['summary']}
          }
        }
        }
      }
    }),
    getSessionsWithChatHistoryByUser: builder.query<{ data: Session[] }, number>({
      query: (userId) => ({
        url: `${ApiRoutes.CHAT_HISTORIES}/${userId}/sessions-linked`,
        method: 'GET',
        params: {
          populate: ['session', 'session.content', 'session.content.picture', 'session.picture'],
          filters: {
            user: {
              id: {
                $eq: userId,
              },
            },
          },
        },
      }),
    }),
    getChatHistoryById: builder.query<{ data: ChatHistory[] }, number>({
      query: (id) => ({
        url: ApiRoutes.CHAT_HISTORIES,
        method: 'GET',
        params: {
          populate: ['session', 'session.content', 'session.content.picture'],
          filters: {
            id: {
              $eq: id,
            },
          },
          sort: ['id:desc'],
        },
      }),
      transformResponse: (response: any) => {
          // Keep the original response structure but parse the messages
          return {
            ...response,
            data: response?.data.map(history => ({
              ...history,
              attributes: {
                ...history.attributes,
                messages: JSON.parse(history.attributes.messages)
              }
            }))
          };
        },
    }),
    getChatHistoryByUserAndSessionAndDate: builder.query<{ data: ChatHistory }, {userId: number, creationDate: string, sessionId: number}>({
      query: ({userId,creationDate,sessionId} ) => ({
        url: `${ApiRoutes.PROGRAM_CHAT_HISTORY}`,
        method: 'GET',
        params:{
          filters: {
            user:{
              $eq: userId
            },

          },
          creationDate:creationDate,
          sessionId: sessionId

        }
      }),
    }),
    // getChatHistoryBySessionId: builder.query<{ data: ChatHistory[] }, number>({
    //   query: (id) => ({
    //     url: ApiRoutes.CHAT_HISTORIES,
    //     method: 'GET',
    //     params: {
    //       populate: ['session', 'session.content', 'session.content.picture'],
    //       filters: {
    //         session: {
    //           id: {
    //             $eq: id,
    //           },
    //         },
    //       },
    //       sort: ['id:desc'],
    //     },
    //   }),
    // }),
    createChatHistory: builder.mutation<any, { messages: Message[]; locale: string; userId: number; summary: string; sessionId?: number; summaryTitle?: string; }>({
      query: ({ messages, locale, userId, sessionId, summary, summaryTitle }) => ({
        url: ApiRoutes.CHAT_HISTORIES,
        method: 'POST',
        data: { data: { messages: JSON.stringify(messages), locale, user: userId, session: sessionId, summary, summary_title: summaryTitle } },
      }),
      invalidatesTags: ['ChatHistory'],
    }),
    updateChatHistoryMessages: builder.mutation<any, { id: string; messages: Message[]; summary?: string; summaryTitle?: string; }>({
      query: ({id, messages, summary, summaryTitle}) => ({
        url: `${ApiRoutes.CHAT_HISTORIES}/${id}`,
        method: 'PUT',
        data:  { data : { messages: JSON.stringify(messages), summary, summary_title: summaryTitle} }
      })
    }),
    createEventLog: builder.mutation<any, any>({
      query: (data) => ({
        url: ApiRoutes.EVENT_LOG,
        method: 'POST',
        data,
      }),
    }),
    createSessionFeedback: builder.mutation<any, { comment: string; isUseful: boolean; sessionId: string }>({
      query: ({ comment, sessionId, isUseful }) => ({
        url: ApiRoutes.SESSION_FEEDBACK,
        method: 'POST',
        data: {
          data: {
            comment,
            session: sessionId,
            isUseful,
          },
        },
      }),
    }),
    getGoals: builder.query<any, void>({
      query: () => ({
        url: ApiRoutes.GOALS,
        method: 'GET',
        params: {
          populate: {
            picture: {
              fields: ['url'],
            },
          },
        },
      }),
    }),
    getUserGoals:  builder.query<any, { status?: string; pageIndex: number; pageSize?: number; }>({
      query: ({pageIndex, pageSize, status}) => ({
        url: `${ApiRoutes.GOALS}/current-user?pagination[page]=${pageIndex}&pagination[pageSize]=${pageSize ?? 9}&pagination[withCount]=true`,
        method: 'GET',
        params:{
            status: status
        },
      }),
    }),
    getUserGoalActions: builder.query<any, { status?: string; pageIndex: number; pageSize?: number; }>({
      query: ({pageIndex, pageSize, status}) => ({
        url: `${ApiRoutes.USER_GOAL_ACTIONS}/current-user?pagination[page]=${pageIndex}&pagination[pageSize]=${pageSize ?? 9}&pagination[withCount]=true`,
        method: 'GET',
        params:{
            status: status
        },
      }),
      keepUnusedDataFor: 60,
      providesTags: (result, error, arg) => {
        return result ? [{ type: 'UserGoalActions' as const, id: result.data.id }, 'UserGoalActions'] : ['UserGoalActions']},
    }),
    createUserGoalActions: builder.mutation<any, { userId: string; goalId: string; actionId: string}>({
      query: ({goalId, actionId, userId}) => ({
        method: 'POST',
        url: ApiRoutes.USER_GOAL_ACTIONS,
        data:{data:{
          action: actionId,
          goal: goalId,
          user: userId
        }}

      }),
      invalidatesTags: ['UserGoalActions']

    }),
    updateUserGoalActions: builder.mutation<any,{ id: string; data: any; }>({
      query: ({id, data}) => ({
        method:'PUT',
        url: `${ApiRoutes.USER_GOAL_ACTIONS}/${id}`,
        data
      })
    }),
    deleteUserGoalActions: builder.mutation<any, { userGoalActionId: string;  goalId: string}>({
      query: ({userGoalActionId, goalId} ) => ({
        method: 'DELETE',
        url: `${ApiRoutes.USER_GOAL_ACTIONS}/${userGoalActionId}`,
        params: {
          userGoalActionId,
          goalId
        }
      }),
      // invalidatesTags:['UserGoalActions']
    }),
    createGoal: builder.mutation<any, {
      title: string;
      description: string;
      userId: string;
      motivationLevel?: string;
      completionDate?: string;
    }>({
      query: ({
          title,
          description,
          userId,
          motivationLevel,
          completionDate
      }) => ({
        url: ApiRoutes.GOALS,
        method: 'POST',
        data: {
          data: {
          user: userId,
          title,
          description,
          motivationLevel,
          completionDate
        }
        }
      }),
      invalidatesTags: ['UserGoalActions']
    }),
    updateGoal : builder.mutation<any , any>({
      query: (data) => ({
        url:`${ApiRoutes.GOALS}/${data.id}`,
        method:'PUT',
        data: {
          data
        }
      }),
      invalidatesTags: ['UserGoal','UserGoalActions'],
    }),
    deleteGoal: builder.mutation<any, {id: string}>({
      query: ({id}) => ({
        url:`${ApiRoutes.GOALS}/${id}`,
        method: 'DELETE',
        params: {
          id: id
        }
      }),
      invalidatesTags:  ['UserGoal'],
    }),
    getInsights: builder.query<any, void>({
      query: () => ({
        url: ApiRoutes.INSIGHTS,
        method: 'GET',
      }),
    }),
    createInsight: builder.mutation<any, {
      userId: string, title: string, content: string, color: string
    }>({
      query:({userId, title, content, color}) => ({
        url: ApiRoutes.INSIGHTS,
        method: 'POST',
        data: { data:{
          user: userId,
          title,
          colour:{
            text: color,
            background: color
          },
          content,
          source:[{
                 __component: "insight-source.dashboard"
               }]
          }

        }
      })
    }),
    deleteInsight: builder.mutation<any, { id: string; }>({
      query: ({id}) => ({
        url: ApiRoutes.INSIGHTS,
        method: 'DELETE',
        params: {
          id: id
        }
      })
    }),
    getOnboardingMessage: builder.query<any, void>({
      query: () => ({
        url: `${ApiRoutes.ONBOARDING_MESSAGE}`,
        method: 'GET',
      }),
    }),
    getPrograms: builder.query<{ data: Program[] }, { populate?: string[]; forpage?: string; location?: string[] }>({
      query: ({ populate, forpage, location }) => ({
        url: ApiRoutes.PROGRAMS,
        method: 'GET',
        params: {
          populate,
          forpage,
          location,
        },
      }),
    }),
    getWelcomeMessage: builder.query<any, { id?: number }>({
      query: ({ id }) => ({
        url: `${ApiRoutes.PROGRAMS}/custom`,
        method: 'GET',
        params: { id },
      }),
    }),
    getProgramStepAnswer: builder.query<{ data: StepAnswer }, { id: number; userProgramId: number }>({
      query: ({ id, userProgramId }) => ({
        url: `${ApiRoutes.PROGRAM_STEPS}/${id}/answer?userProgramId=${userProgramId}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => (result ? [{ type: 'StepAnswer' as const, id: result.data.id }, 'StepAnswer'] : ['StepAnswer']),
    }),
    getPrompt: builder.query<{ data: Prompt }, void>({
      query: () => ({
        url: `${ApiRoutes.PROMPT}?populate=prompts`,
        method: 'GET',
      }),
    }),
    getFollowUpPrompt: builder.query<{ data: any }, void>({
      query: () => ({
        url: `${ApiRoutes.PROMPT}/follow-up`,
        method: 'GET',
      }),
    }),
    getSessionDetails: builder.query<{ data: Session[] }, string>({
      query: (id) => ({
        url: `${ApiRoutes.SESSIONS}`,
        method: 'GET',
        params: {
          filters: {
            id: {
              $eq: id,
            },
          }
        },
      }),
    }),
    getForYouSessions: builder.query<{ data: Session[] }, { filterType: string; locale: string }>({
      query: ({ filterType, locale }) => ({
        url: `${ApiRoutes.SESSIONS}/for-you`,
        method: 'GET',
        params: { filterType, locale },
      }),
    }),
    uploadProfilePicture: builder.mutation<any, any>({
      query: (data) => ({
        url: ApiRoutes.UPLOAD,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data,
      }),
      invalidatesTags: ['UserProfile'],
    }),
    getUserActions: builder.query<any, { status?: string; pageIndex: number; pageSize?: number; }>({
      query: ({pageIndex, pageSize, status}) => ({
        url: `${ApiRoutes.USER_ACTIONS}/current-user?pagination[page]=${pageIndex}&pagination[pageSize]=${pageSize ?? 9}&pagination[withCount]=true`,
        method: 'GET',
        params:{
            status: status
        },
      }),
      providesTags:(result, error, arg) =>
        result ? [{ type: 'UserAction' as const, id: result.data.id }, 'UserAction'] : ['UserAction'],
    }),
    createUserAction: builder.mutation<any , {
      title: string;
      note: string;
      userId: string;
      contextType: string;
      contextId: number;
      motivationLevel: number;
      completionDate?: string;
      status: string;
    }>({
      query: ({
        title,
        note,
        userId,
        contextType,
        contextId,
        motivationLevel,
        completionDate,
        status
      }) => ({
        url: ApiRoutes.USER_ACTIONS,
        method:'POST',
        data:{
          data:{
            title,
            note,
            user: userId,
            contextType,
            contextId,
            motivationLevel,
            completionDate,
            status
          }
        }
      }),
      invalidatesTags:  [ 'UserAction'],

    }),
    deleteUserAction: builder.mutation<any, {id: string}>({
      query: ({id}) => ({
        url: `${ApiRoutes.USER_ACTIONS}/${id}`,
        method: 'DELETE',
        params:{
          id:id
        }

      }),
      invalidatesTags:  [ 'UserAction','UserGoalActions'],
    }),
    updateUserAction: builder.mutation<any, { id: string; data: any; }>({
      query: ({id,data}) => ({
        url: `${ApiRoutes.USER_ACTIONS}/${id}`,
        method: 'PUT',
        data:{
          data: data
        },
        transformResponse: (response) => {
          const userActions = response.data.find((action) => action.id === data.id) || data;
          const lumisAction = response.lumisAction;
          return [{ userActions, lumisAction }];
        },
      }),

      invalidatesTags:  [ 'UserAction','UserGoalActions'],
    }),
    getUserActivityAnswers: builder.query<{ data: UserActivityAnswer }, { userProgramId: number; stepId: number; activityId: number }>({
      query: ({ userProgramId, stepId, activityId }) => ({
        url: `${ApiRoutes.USER_ACTIVITY_ANSWERS}/activity/${activityId}`,
        method: 'GET',
        params: {
          step: stepId,
          userProgram: userProgramId,
        },
      }),
      providesTags: (result, error, arg) =>
        result ? [{ type: 'UserActivityAnswer' as const, id: result.data.id }, 'UserActivityAnswer'] : ['UserActivityAnswer'],
    }),
    markActivityAsComplete: builder.mutation<any, number>({
      query: (id) => ({
        url: `${ApiRoutes.USER_ACTIVITY_ANSWERS}/${id}/complete`,
        method: 'POST',
      }),
      invalidatesTags: ['UserActivityAnswer'],
    }),
    getUserNotifications: builder.query<any, void>({
      query: () => ({
        url: ApiRoutes.USER_NOTIFICATIONS,
        method: 'GET',
      }),
    }),
    getUserProfile: builder.query<ExtendedUserProfile, void>({
      query: () => ({
        url: `${ApiRoutes.USERS}/me`,
        method: 'GET',
        params: {
          populate: ['userSettings', 'role', 'picture'],
        },
        transformResponse: [
          (response: string): ExtendedUserProfile => {
            // TODO: make this parser type-safe
            const user: UserProfile = JSON.parse(response);
            const token = getCookies('jwt') ?? '';

            setCookies('lang', user.language);
            setCookies('user', JSON.stringify(user));
            if (user.role.name === 'Admin') {
              setCookies('role', 'admin');
            }

            return {
              isAuthenticated: !!token,
              jwt: token,
              isAdmin: getCookies('role') === 'admin',
              ...(user && user),
            };
          },
        ],
      }),
      providesTags: (result, error, arg) => (result ? [{ type: 'UserProfile' as const, id: result.id }, 'UserProfile'] : ['UserProfile']),
    }),
    updateUserProfile: builder.mutation<
      any,
      { userId: number; name?: string; email?: string; password?: string; selfDefinition?: string; ambition?: string }
    >({
      query: ({ userId, name, email, password, selfDefinition, ambition }) => ({
        url: `${ApiRoutes.USERS}/${userId}`,
        method: 'PUT',
        params: {
          name,
          email,
          password,
          selfDefinition,
          ambition,
        },
      }),
      invalidatesTags: ['UserProfile'],
    }),
    createUserProgramAnswer: builder.mutation<{ data: UserProgramAnswer }, { body: any }>({
      query: ({ body }) => ({
        url: ApiRoutes.USER_PROGRAM_ANSWER,
        method: 'POST',
        data: body,
        params: {
          populate,
        },
      }),
      invalidatesTags: ['UserProgramAnswer'],
    }),
    getAllUserProgramAnswers: builder.query<{ data: UserProgramAnswer[] }, void>({
      query: () => ({
        url: ApiRoutes.USER_PROGRAM_ANSWER,
        method: 'GET',
        params: {
          filters: {
            status: {
              $ne: 'deleted',
            },
          },
          populate: [
            'program',
            'program.content',
            'program.content.picture',
            'stepAnswers',
            'stepAnswers.activityAnswers.activity',
            'stepAnswers.activityAnswers.activity.session',
            'stepAnswers.activityAnswers.activity.session.content',
            'stepAnswers.activityAnswers.activity.session.content.picture',
          ],
        },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [...result.data.map(({ id }) => ({ type: 'UserProgramAnswer' as const, id })), 'UserProgramAnswer']
          : ['UserProgramAnswer'],
    }),
    getOngoingUserProgramAnswer: builder.query<{ data: UserProgramAnswer[] }, void>({
      query: () => ({
        url: ApiRoutes.USER_PROGRAM_ANSWER,
        method: 'GET',
        params: {
          filters: {
            status: {
              $eq: 'active',
            },
          },
        },
      }),
      providesTags: (result, error, arg) =>
        result
          ? [...result.data.map(({ id }) => ({ type: 'UserProgramAnswer' as const, id })), 'UserProgramAnswer']
          : ['UserProgramAnswer'],
    }),
    getUserProgramAnswer: builder.query<{ data: UserProgramAnswer }, number>({
      query: (id: number) => ({
        url: `${ApiRoutes.USER_PROGRAM_ANSWER}/${id}`,
        method: 'GET',
        params: {
          populate,
        },
      }),
      providesTags: (result, error, arg) =>
        result ? [{ type: 'UserProgramAnswer' as const, id: result.data.id }, 'UserProgramAnswer'] : ['UserProgramAnswer'],
    }),
    getUserProgramAnswerActivities: builder.query<{ data: UserProgramAnswerActivity }, number>({
      query: (id: number) => ({
        url: `${ApiRoutes.USER_PROGRAM_ANSWER}/${id}/activities`,
        method: 'GET',
      }),
    }),
    updateUserProgramAnswer: builder.mutation<{ data: UserProgramAnswer }, { id: number; data: any }>({
      query: ({ id, data }) => ({
        url: `${ApiRoutes.USER_PROGRAM_ANSWER}/${id}`,
        method: 'PUT',
        data: { data },
        params: {
          populate,
        },
      }),
      invalidatesTags: ['UserProgramAnswer'],
    }),
    deleteUserProgramAnswer: builder.mutation<any, number>({
      query: (id: number) => ({
        url: `${ApiRoutes.USER_PROGRAM_ANSWER}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserProgramAnswer'],
    }),
    startUserProgram: builder.mutation<any, { id: number; body: any }>({
      query: ({ id, body }) => ({
        url: `${ApiRoutes.USER_PROGRAM_ANSWER}/${id}/start`,
        method: 'POST',
        data: body,
        params: {
          populate,
        },
      }),
      invalidatesTags: ['UserProgramAnswer'],
    }),
    getUserSessionAnswers: builder.query<{ data: UserSessionAnswer[] }, void>({
      query: () => ({
        url: ApiRoutes.USER_SESSION_ANSWERS,
        method: 'GET',
        params: {
          'filters[completed]': true,
          populate: [
            'session',
            'session.needs',
            'session.needs.content',
            'session.content',
            'session.content.picture',
            'session.questions',
            'session.questions.content',
            'session.questions.content.groups',
            'session.questions.content.options',
            'answers',
            'answers.answer',
          ],
        },
      }),
    }),
    getUserSessionAnswersBySessionId: builder.query<{ data: UserSessionAnswer[] }, number>({
      query: (id) => ({
        url: ApiRoutes.USER_SESSION_ANSWERS,
        method: 'GET',
        params: {
          filters: {
            session: {
              id: {
                $eq: id,
              },
            },
          },
        },
      }),
    }),
    markUserSessionAsComplete: builder.mutation<any, number>({
      query: (id) => ({
        url: `${ApiRoutes.USER_SESSION_ANSWERS}/${id}/complete`,
        method: 'POST',
      }),
    }),
    addStepSummary: builder.mutation<any, { userStepAnswerId: number; answers: any; isCompleted: boolean }>({
      query: ({ userStepAnswerId, answers, isCompleted }) => ({
        url: `${ApiRoutes.USER_STEP_ANSWERS}/${userStepAnswerId}`,
        method: 'PUT',
        data: {
          data: {
            stepSummaryAnswer: answers,
            stepSummaryCompleted: isCompleted,
            completed: isCompleted,
            ...(isCompleted && { completedAt: new Date() }),
          },
        },
        params: {
          populate: ['activityAnswers', 'activityAnswers.activity', 'stepSummaryAnswer'],
        },
      }),
      invalidatesTags: ['StepAnswer', 'UserProgramAnswer'],
    }),
    getUserSettings: builder.query<UserSettings, void>({
      query: () => ({
        url: ApiRoutes.USER_SETTINGS,
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        result ? [{ type: 'UserSettings' as const, id: result.id }, 'UserSettings'] : ['UserSettings'],
    }),
    updateUserSettings: builder.mutation<UserSettings, Partial<UserSettings>>({
      query: (data) => ({
        url: `${ApiRoutes.USER_SETTING}/me`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['UserProfile', 'UserSettings'],
    }),
    getAiToolsByType: builder.query<AiTool, { type: string; }>({
      query: (type) => ({
        url: `${ApiRoutes.AI_TOOLS}/filter/${type}`,
        method: 'GET',
      })
    }),
    filterAiToolsByNeedName: builder.query<AiTool, { needName?: string; }>({
      query: (needName) => ({
        method: 'GET',
        url : `${ApiRoutes.AI_TOOLS}/filter-tool/${needName ? needName : 'null'}`
      })
    })
  }),
});

export const {
  useGetSessionsWithChatHistoryByUserQuery,
  useLazyGetSessionsWithChatHistoryByUserQuery,
  useLoginMutation,
  useRegisterMutation,
  useGetChatHistoryByIdQuery,
  useCreateChatHistoryMutation,
  useCreateEventLogMutation,
  useCreateSessionFollowUpMutation,
  useGetSessionFollowUpsByUserIdQuery,
  useGetSessionFollowUpByCurrentSessionIdQuery,
  useGetChatHistoryByUserAndSessionAndDateQuery,
  useLazyGetChatHistoryByUserAndSessionAndDateQuery,
  useCreateChathistoryFollowUpSessionMutation,
  useGetChatHistoryFollowUpSessionQuery,
  useGetSessionFollowUpByAllParamsQuery,
  useLazyGetChatHistoryFollowUpSessionQuery,
  useGetFollowUpPromptQuery,
  useGetGoalsQuery,
  useGetUserGoalActionsQuery,
  useGetInsightsQuery,
  useGetOnboardingMessageQuery,
  useGetProgramsQuery,
  useGetWelcomeMessageQuery,
  useLazyGetProgramStepAnswerQuery,
  useGetPromptQuery,
  useGetSessionDetailsQuery,
  useGetForYouSessionsQuery,
  useLazyGetForYouSessionsQuery,
  useUploadProfilePictureMutation,
  useUpdateUserProfileMutation,
  useUpdateUserActionMutation,
  useGetUserActivityAnswersQuery,
  useMarkActivityAsCompleteMutation,
  useGetUserNotificationsQuery,
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useCreateUserProgramAnswerMutation,
  useGetOngoingUserProgramAnswerQuery,
  useGetAllUserProgramAnswersQuery,
  useLazyGetOngoingUserProgramAnswerQuery,
  useGetUserProgramAnswerQuery,
  useLazyGetUserProgramAnswerQuery,
  useGetUserProgramAnswerActivitiesQuery,
  useUpdateUserProgramAnswerMutation,
  useDeleteUserProgramAnswerMutation,
  useStartUserProgramMutation,
  useGetUserSessionAnswersQuery,
  useGetUserSessionAnswersBySessionIdQuery,
  useMarkUserSessionAsCompleteMutation,
  useAddStepSummaryMutation,
  useGetUserSettingsQuery,
  useUpdateUserSettingsMutation,
  useCreateSessionFeedbackMutation,
  useCreateGoalMutation,
  useCreateInsightMutation,
  useCreateUserActionMutation,
  useCreateUserGoalActionsMutation,
  useUpdateGoalMutation,
  useUpdateUserGoalActionsMutation,
  useDeleteUserGoalActionsMutation,
  useGetUserGoalsQuery,
  useGetUserActionsQuery,
  useDeleteGoalMutation,
  useDeleteUserActionMutation,
  useUpdateChatHistoryMessagesMutation,
  useGetAiToolsByTypeQuery,
  useFilterAiToolsByNeedNameQuery,
  useLazyFilterAiToolsByNeedNameQuery
} = apiSlice;
